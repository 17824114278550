import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ImageField,
  TopToolbar,
  ListButton,
  DeleteButton,
  DateField,
  FunctionField,
  BooleanField,
} from 'react-admin';

const Actions = ({ data, resource }: any) => (
  <TopToolbar>
    <ListButton />
    <DeleteButton
      record={data}
      resource={resource}
      mutationMode="pessimistic"
      label="Resolve"
    />
  </TopToolbar>
);

function ShowReport(props: any) {
  return (
    <Show {...props} actions={<Actions />} hasList basePath="reports">
      <SimpleShowLayout>
        <FunctionField
          label="Initiator"
          render={(record: any) =>
            `${record.initiatorUser.firstName} ${record.initiatorUser.lastName}`
          }
        />
        <ImageField source="initiatorUser.avatarUrl" />
        <br />
        <FunctionField
          label="Recipient"
          render={(record: any) =>
            `${record.recipientUser.firstName} ${record.recipientUser.lastName}`
          }
        />
        <ImageField source="recipientUser.avatarUrl" />
        <BooleanField source="spam" />
        <BooleanField source="inappropriateMaterial" />
        <BooleanField source="harmfulDangerousActions" />
        <DateField source="createdAt" label="Creation date" />
      </SimpleShowLayout>
    </Show>
  );
}

export default ShowReport;
