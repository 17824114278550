import { GetListParams } from 'ra-core';

type BaseQuery = {
  roleFilter: string;
  join: string[];
  [key: string]: any;
} & GetListParams;

type Query = Partial<BaseQuery>;

export const nestQueryBuilder = (query: Query) => {
  const nestQuery = new URLSearchParams();

  if (query.pagination) {
    const { page, perPage } = query.pagination;
    nestQuery.append('page', `${page}`);
    nestQuery.append('limit', `${perPage}`);
  }

  if (query.sort) {
    const { field, order } = query.sort;
    const sort = `${field},${order}`;
    nestQuery.append('sort', sort);
  }

  if (query.filter) {
    const filter = query.filter;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach((key) => {
      nestQuery.append('filter', `${key}||$startsL||${filter[key]}`);
    });
  }

  if (query.roleFilter) {
    nestQuery.append('filter', `role||$eq||${query.roleFilter}`);
  }

  if (query.join) {
    query.join.forEach((joinField) => {
      nestQuery.append('join', joinField);
    });
  }

  return nestQuery;
};
