import { useAuth0 } from '@auth0/auth0-react';
import { login } from 'api';
import { AuthProvider } from 'ra-core';
import { useEffect, useMemo } from 'react';
import StorageService from 'services/StorageService';

export const useAuth = () => {
  const {
    logout,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        StorageService.setItem('accessToken', token);
      } catch (e) {
        loginWithRedirect({ redirectUri: window.location.origin });
      }
    };

    getAccessToken();
  }, [isAuthenticated]);

  const authProvider = useMemo<AuthProvider>(() => {
    return {
      login: async () => {
        return new Promise((resolve) => resolve(false));
      },
      checkAuth: async () => {
        if (isAuthenticated || isLoading) {
          return;
        }

        loginWithRedirect({ redirectUri: window.location.origin });
      },
      checkError: (error: any) => {
        return new Promise((resolve, reject) => {
          resolve();
        });
      },
      getPermissions: (props) => {
        return new Promise((resolve) => resolve(false));
      },
      logout: async () => {
        logout({ returnTo: window.location.origin });
      },
      getIdentity: async () => {
        try {
          const { data } = await login();

          return {
            id: data.id,
            avatar: data.avatUrl,
            fullName: `${data.firstName} ${data.lastName}`,
          };
        } catch (e) {
          return { id: 0 };
        }
      },
    };
  }, [isAuthenticated, isLoading]);

  return [authProvider, isAuthenticated] as const;
};
