import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageField,
  SelectInput,
  TopToolbar,
  ShowButton,
  ListButton,
  DeleteButton,
  useEditController,
} from 'react-admin';

// const Title = ({ record }: any) => (
//   <div>Coach: {`${record.firstName} ${record.lastName}`}</div>
// );

const Actions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ShowButton record={data} />
    <ListButton />
    <DeleteButton
      record={data}
      resource={resource}
      mutationMode="pessimistic"
    />
  </TopToolbar>
);

const EditCoach = (props: any) => {
  const {record} = useEditController()
  const Title = () => (
    <div>Coach: {`${record?.firstName} ${record?.lastName}`}</div>
  );
  return(
  <Edit
    {...props}
    title={<Title />}
    actions={<Actions />}
    mutationMode="pessimistic"
  >
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput disabled label="Email" source="email" />
      <ImageField source="avatarUrl" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput multiline source="description" />
      <TextInput source="experience" />
      <SelectInput
        source="subscriptionStatus"
        choices={[{ id: 'free', name: 'Free' }]}
      />
    </SimpleForm>
  </Edit>
)};

export default EditCoach;
