import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ImageField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
} from 'react-admin';

const ClientsFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="firstName" alwaysOn />
  </Filter>
);

function ClientsList(props: any) {

  return (
    <List {...props} filters={<ClientsFilters />} hasEdit hasShow>
      <Datagrid>
        <ImageField
          source="avatarUrl"
          label="Avatar"
          className={'avatar'}
          sx={{'& > img': { maxHeight: 32, maxWidth: 32, borderRadius: '50%' }}}
          sortable={false}
        />
        <EmailField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="weight" />
        <TextField source="height" />
        <DateField source="createdAt" label="Register at" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

export default ClientsList;
