import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  UrlField,
  ReferenceField,
} from 'react-admin';

function VideosList(props: any) {
  return (
    <List {...props}>
      <Datagrid>
        <UrlField source="videoUrl" label="URL" />
        <ReferenceField
          label="Author"
          source="authorId"
          reference="admin/users/coaches"
        >
          <>
            <TextField source="firstName" />
            &nbsp;
            <TextField source="lastName" />
          </>
        </ReferenceField>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="duration" />
        <TextField source="viewCount" emptyText="0" />
        <DateField source="createdAt" label="Uploaded at" />
      </Datagrid>
    </List>
  );
}

export default VideosList;
