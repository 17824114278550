import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ImageField,
  EditButton,
  TopToolbar,
  ListButton,
  DeleteButton,
  DateField,
  useShowController,
} from 'react-admin';

const Actions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton record={data} />
    <ListButton />
    <DeleteButton
      record={data}
      resource={resource}
      mutationMode="pessimistic"
    />
  </TopToolbar>
);

function ShowCoach(props: any) {
  const {record} = useShowController()
  const Title = () => (
    <div>Coach: {`${record?.firstName} ${record?.lastName}`}</div>
  );
  return (
    <Show
      {...props}
      title={<Title />}
      actions={<Actions />}
      hasList
      basePath="admin/users/coaches"
    >
      <SimpleShowLayout>
        <ImageField source="avatarUrl" label="Avatar" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <RichTextField source="description" />
        <TextField source="experience" />
        <TextField source="subscriptionStatus" />
        <DateField source="createdAt" label="Registration date" />
      </SimpleShowLayout>
    </Show>
  );
}

export default ShowCoach;
