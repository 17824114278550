import { DataProvider } from 'ra-core';
import {
  coachesDataProvider,
  clientsDataProvider,
  videosDataProvider,
  reportsDataProvider,
  usersDataProvider,
} from './routesProviders';
import { RouteDataProvider } from './types';

const resourceProviderMapping: Record<string, RouteDataProvider> = {
  'admin/users/coaches': coachesDataProvider,
  'admin/users/clients': clientsDataProvider,
  'admin/videos': videosDataProvider,
  'admin/reports': reportsDataProvider,
  'admin/users': usersDataProvider,
};

export const mainProvider = {
  getMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getMany(params);
  },
  getOne: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getOne(params);
  },
  create: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.create(params);
  },
  delete: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.delete(params);
  },
  deleteMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.deleteMany(params);
  },
  getList: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getList(params);
  },
  getManyReference: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getManyReference(params);
  },
  update: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.update(params);
  },
  updateMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.updateMany(params);
  },
} as DataProvider;
