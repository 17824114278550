import axios, {
  CancelToken as AxiosCancelToken,
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import StorageService from 'services/StorageService';
import { BASE_URL } from 'config';

export type CancelToken = AxiosCancelToken;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestError<
  T = { message: string; statusCode: number; error: string }
> = AxiosError<T>;
export type RequestConfig = AxiosRequestConfig;

const httpClient = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
});

httpClient.interceptors.request.use((config: any) => {
  const accessToken = StorageService.getAccessToken();
  if (!accessToken) {
    return config;
  }

  if (config.withCredentials === false) {
    return config;
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...config.headers,
  };

  return { ...config, headers };
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      throw error.response.data;
    }

    if (error.data) {
      throw error.data;
    }

    throw error;
  }
);

export const createSourceCancelToken = () => {
  return axios.CancelToken.source();
};

export default httpClient;
