import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  BooleanField,
  ReferenceField,
} from 'react-admin';

const getInitiatorLink = (user: any) => {
  const isUserCoach = user.role === 'coach';
  if (isUserCoach) {
    return `users/coaches/${user.id}`;
  }

  const isUserClient = user.role === 'client';
  if (isUserClient) {
    return `users/clients/${user.id}`;
  }

  return '';
};

function ReportsList(props: any) {
  return (
    <List {...props} hasEdit hasShow>
      <Datagrid>
        <ReferenceField
          label="Initiator"
          source="initiatorUserId"
          reference="admin/users"
          link={(record: any) => getInitiatorLink(record.initiatorUser)}
        >
          <React.Fragment>
            <TextField source="firstName" />
            &nbsp;
            <TextField source="lastName" />
          </React.Fragment>
        </ReferenceField>
        <ReferenceField
          label="Recipient"
          source="recipientUserId"
          reference="admin/users"
          link={(record: any) => getInitiatorLink(record.recipientUser)}
        >
          <React.Fragment>
            <TextField source="firstName" />
            &nbsp;
            <TextField source="lastName" />
          </React.Fragment>
        </ReferenceField>
        <BooleanField source="spam" />
        <BooleanField source="inappropriateMaterial" />
        <BooleanField source="harmfulDangerousActions" />
        <DateField source="createdAt" label="Created at" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

export default ReportsList;
