import { default as httpClient } from './htppClient';

export const getMany = (prefix: string, params: any) => {
  return httpClient.get(`${prefix}`, { params });
};

export const getOne = (prefix: string, id: string, params?: any) => {
  return httpClient.get(`${prefix}/${id}`, { params });
};

export const deleteOne = (prefix: string, id: string) => {
  return httpClient.delete(`${prefix}/${id}`);
};

export const updateOne = (prefix: string, id: string, body: any) => {
  return httpClient.patch(`${prefix}/${id}`, body);
};

export const login = () => {
  return httpClient.post('admin/auth/login');
};
