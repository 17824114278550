import { GetListParams } from 'ra-core';
import { nestQueryBuilder } from 'utils';
import * as api from 'api';
import { RouteDataProvider } from 'provider/types';

const route = 'admin/videos';

//@ts-ignore
export const videosDataProvider = {
  getList: async (params: GetListParams) => {
    const { data } = await api.getMany(route, nestQueryBuilder(params));
    return data;
  },
  getOne: async (params) => {
    return api.getOne(route, `${params.id}`);
  },
  delete: async (params) => {
    return api.deleteOne(route, `${params.id}`);
  },
  deleteMany: async (params) => {
    const data = await Promise.all(
      params.ids.map(async (id) => {
        await api.deleteOne(route, `${id}`);
      })
    );

    return { data };
  },
  update: async (params) => {
    return api.updateOne(route, `${params.id}`, params.data);
  },
} as RouteDataProvider;
