import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  REACT_APP_AUDIENCE,
  REACT_APP_CLIENT_ID,
  REACT_APP_DOMAIN,
} from 'config';

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_DOMAIN!}
      clientId={REACT_APP_CLIENT_ID!}
      audience={REACT_APP_AUDIENCE!}
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);
