import { GetListParams } from 'ra-core';
import { nestQueryBuilder } from 'utils';
import * as api from 'api';
import { RouteDataProvider } from 'provider/types';

const route = 'admin/reports';

//@ts-ignore
export const reportsDataProvider = {
  getList: async (params: GetListParams) => {
    const { data } = await api.getMany(
      route,
      nestQueryBuilder({
        ...params,
        join: ['initiatorUser', 'recipientUser'],
      })
    );
    return data;
  },
  getOne: async (params) => {
    return api.getOne(
      route,
      `${params.id}`,
      nestQueryBuilder({
        ...params,
        join: ['initiatorUser', 'recipientUser'],
      })
    );
  },
  getMany: async (params) => {
    const data = await Promise.all(
      params.ids.map(async (id) => {
        const { data } = await api.getOne(route, `${id}`);
        return data;
      })
    );
    return { data };
  },
  delete: async (params) => {
    return api.deleteOne(route, `${params.id}`);
  },
  deleteMany: async (params) => {
    const data = await Promise.all(
      params.ids.map(async (id) => {
        await api.deleteOne(route, `${id}`);
      })
    );

    return { data };
  },
  update: async (params) => {
    return api.updateOne(route, `${params.id}`, params.data);
  },
} as RouteDataProvider;
