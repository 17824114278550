import { NotificationType } from 'react-admin';
import { getErrorMessage } from './getErrorMessage';

export class NotifyVO {
  public success(message: string): {
    message: string;
    type: NotificationType | undefined;
  } {
    return {
      message,
      type: 'success',
    };
  }

  public error(err: unknown): {
    message: string;
    type: NotificationType | undefined;
  } {
    const error = getErrorMessage(err);

    return {
      message: error,
      type: 'error',
    };
  }

  public warning(message: string): {
    message: string;
    type: NotificationType | undefined;
  } {
    return {
      message,
      type: 'warning',
    };
  }
}

export const Notify = new NotifyVO();