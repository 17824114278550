import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from 'provider';
import { useAuth } from './hooks';
import {
  ClientsList,
  CoachesList,
  EditClient,
  EditCoach,
  ReportsList,
  ShowClient,
  ShowCoach,
  ShowReport,
  VideosList,
} from 'resources';

import FitnessCenter from '@mui/icons-material/FitnessCenter';
import DirectionsRun from '@mui/icons-material/DirectionsRun';
import VideoLibrary from '@mui/icons-material/VideoLibrary';
import Report from '@mui/icons-material/Report';

function App() {
  const [authProvider, isAuthenticated] = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Admin
      authProvider={authProvider}
      title="Fitness App Admin"
      dataProvider={dataProvider}
    >
      <Resource
        name="admin/users/coaches"
        list={CoachesList}
        edit={EditCoach}
        show={ShowCoach}
        options={{ label: 'Coaches' }}
        icon={FitnessCenter}
      />
      <Resource
        name="admin/users/clients"
        list={ClientsList}
        edit={EditClient}
        show={ShowClient}
        options={{ label: 'Clients' }}
        icon={DirectionsRun}
      />
      <Resource
        name="admin/videos"
        list={VideosList}
        options={{ label: 'Videos' }}
        icon={VideoLibrary}
      />
      <Resource
        name="admin/reports"
        list={ReportsList}
        show={ShowReport}
        options={{ label: 'Reports' }}
        icon={Report}
      />
      <Resource name="admin/users" intent="registration" />
    </Admin>
  );
}

export default App;
