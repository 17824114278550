import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ImageField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
} from 'react-admin';

const CoachesFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="firstName" alwaysOn />
  </Filter>
);

function CoachesList(props: any) {

  return (
    <List {...props} filters={<CoachesFilter />} hasEdit hasShow>
      <Datagrid>
        <ImageField
          source="avatarUrl"
          label="Avatar"
          className={'avatar'}
          sx={{'& > img': { maxHeight: 32, maxWidth: 32, borderRadius: '50%' } }}
          sortable={false}
        />
        <EmailField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="experience" />
        <TextField source="stripeCustomerId" label="Stripe ID" />
        <TextField source="subscription.product.name" label="Subscription" />
        <DateField source="createdAt" label="Register at" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

export default CoachesList;
