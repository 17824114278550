import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  EditButton,
  TopToolbar,
  ListButton,
  DeleteButton,
  DateField,
  useShowController
} from 'react-admin';

const Actions = ({ data, resource }: any) => (
  <TopToolbar>
    <EditButton record={data} />
    <ListButton />
    <DeleteButton
      record={data}
      resource={resource}
      mutationMode="pessimistic"
    />
  </TopToolbar>
);

const ShowClient = (props: any) => {
  const {record} = useShowController()
  const Title = () => (
    <div>Client: {`${record?.firstName} ${record?.lastName}`}</div>
  );
  return (
    <Show
      {...props}
      title={<Title />}
      actions={<Actions />}
      hasList
      basePath="admin/users/coaches"
    >
      <SimpleShowLayout>
        <ImageField source="avatarUrl" label="Avatar" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="weight" />
        <TextField source="height" />
        <TextField source="subscriptionStatus" />
        <DateField source="createdAt" label="Registration date" />
      </SimpleShowLayout>
    </Show>
  );
}

export default ShowClient;
